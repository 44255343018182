import React from "react";
import { graphql } from "gatsby";

import { useLocale } from "context/locale";

import Layout from "components/Layout";
import PageInfo from "components/PageInfo";

const PageInfoContent = ({ variant }) => {
  const { t } = useLocale();
  return (
    <PageInfo
      title="404"
      text={t(
        "We are sorry, but we were unable to find such a page on our website."
      )}
      variant={variant}
      button={{
        label: t("Home"),
        href: t("index_link"),
      }}
    />
  );
};

const NotFoundPage = ({ location, data: { contact } }) => {
  return (
    <Layout
      location={location}
      seo={{ title: "404" }}
      contact_data={contact?.acfContact}
    >
      <PageInfoContent variant="notfound" />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
